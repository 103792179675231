import {HttpErrorResponse} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {LoadingService} from "../loading/loading.service";
import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";

@Injectable()
export class ErrorService {

  private readonly showDialogSubject = new Subject<ErrorItem>();

  constructor(
    private readonly loadingService: LoadingService,
    private readonly messageService: MessageService
  ) {
  }

  showDialog(title: string, message: string, multipleLines: boolean = false, width: string = '500px'): void {
    this.showDialogSubject.next({
      key: title,
      errors:
        {
          title: title,
          message: message,
          multipleLines: multipleLines,
          width: width
        } as DialogError
    });
  }

  showResponseStatus(success: boolean, message?: string): void {
    if (success) {
      this.messageService.add({severity: 'success', summary: message ? message : 'Uložené', contentStyleClass: 'single'});
    } else {
      this.messageService.add({severity: 'error', summary: message ? message : 'Chyba', contentStyleClass: 'single'});
    }
  }

  onShowDialogError(): Observable<ErrorItem> {
    return this.showDialogSubject.asObservable();
  }

  public handleError(error: HttpErrorResponse | string, hideLoading = false) {
    console.error(error);
    if (hideLoading) {
      this.loadingService.hideLoading();
    }
    this.showDialog('Chyba', 'Volaná služba je dočasne nedostupná.');
  }
}

export interface HasErrorItem {
  stringErrors?: boolean;
  validationErrors?: boolean;
}

export interface ErrorItem {
  key: string;
  errors?: string[] | DialogError;
  type?: string;
}

export interface DialogError {
  title: string;
  message: string;
  multipleLines: boolean;
  width?: string;
}
