/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ClientData } from '../models/client-data';
import { getAgentData } from '../fn/user/get-agent-data';
import { GetAgentData$Params } from '../fn/user/get-agent-data';
import { resetPassword } from '../fn/user/reset-password';
import { ResetPassword$Params } from '../fn/user/reset-password';


/**
 * Služby pre správu používateľa
 */
@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAgentData()` */
  static readonly GetAgentDataPath = '/client/agentData';

  /**
   * Služba pre načítanie karty klienta.
   *
   * Služba vráti kartu klienta pre prihláseného používateľa
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentData$Response(params?: GetAgentData$Params, context?: HttpContext): Observable<StrictHttpResponse<ClientData>> {
    return getAgentData(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie karty klienta.
   *
   * Služba vráti kartu klienta pre prihláseného používateľa
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentData(params?: GetAgentData$Params, context?: HttpContext): Observable<ClientData> {
    return this.getAgentData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClientData>): ClientData => r.body)
    );
  }

  /** Path part for operation `resetPassword()` */
  static readonly ResetPasswordPath = '/client/resetPassword';

  /**
   * Služba na reset hesla.
   *
   * Služba na reset hesla
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPassword$Response(params?: ResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return resetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba na reset hesla.
   *
   * Služba na reset hesla
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPassword(params?: ResetPassword$Params, context?: HttpContext): Observable<boolean> {
    return this.resetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
