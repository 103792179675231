import {Injectable} from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  show = false;
  startLoadingCount = 0;

  showLoading(): void {
    this.show = true;
    this.startLoadingCount++;
  }

  hideLoading(hideAfterLast?: boolean): void {
    if (!hideAfterLast) {
      this.show = false;
      this.startLoadingCount = 0;
    } else {
      if (this.startLoadingCount > 0) {
        this.startLoadingCount--;
      }
      this.show = this.startLoadingCount > 0;
    }
  }
}
