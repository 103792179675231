export const HOME_PAGE_CODE = 'HOME_PAGE';

export class Utils {
  /**
   * Checks if value is empty.
   *
   * @param value
   * @return true | false
   */
  static isEmpty(value: any): boolean {
    if (typeof value === 'string') {
      return value === 'null' || (value !== 'null' && value.length === 0) || value === 'XNA';
    } else if (Array.isArray(value)) {
      return value.length === 0;
    } else if (value instanceof Date) {
      return false;
    } else if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length === 0;
    }
    return value === undefined || value === null;
  }

  static isNotEmpty(value: any): boolean {
    return !Utils.isEmpty(value);
  }

  public static getUserInitials(userScreenName: string | undefined): string | undefined {
    if (!userScreenName) return '';
    const nameParts = userScreenName.split(' ');
    return nameParts
      .map(part => part.charAt(0).toUpperCase())
      .join('');
  }

  /**
   * Metoda na stiahnutie dokumentu. Ak subor obsahuje priponu, tak sa stiahne taky ako je. Ak nie, tak sa pripona da na koniec suboru
   * @param pdfData
   * @param typeOfFile
   */
  static downloadFile(pdfData: any, typeOfFile?: string): void {
    // vytiahneme priponu
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(pdfData.fileName)![1];
    let fileName = pdfData.fileName;
    // ak nie je pripona, tak sa ako undefined, tak sa pokusime priponu dat
    if (Utils.isEmpty(ext)) {
      // ak sa poslala pripona, tak dame tu, inak dame default .pdf
      fileName = Utils.isNotEmpty(typeOfFile) ? (pdfData.fileName + '.' + typeOfFile) : pdfData.fileName + '.pdf';
    }
    const linkElem = document.createElement('a');
    linkElem.href = URL.createObjectURL(this.convertDataURIToBinary(pdfData.data, fileName));
    linkElem.download = fileName;
    linkElem.target = '_blank';
    linkElem.click();
  }

  static convertDataURIToBinary(base64str: string, fileName?: string): Blob {
    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    let type = 'application/pdf';
    if (this.isNotEmpty(fileName)) {
      const extension = fileName!.split('.').pop();
      switch (extension) {
        case 'zip':
          type = 'application/zip';
          break;
        case 'pdf':
          type = 'application/pdf';
          break;
        case 'jpg':
        case 'jpeg':
          type = 'image/jpeg';
          break;
        case 'png':
          type = 'image/png';
          break;
        case 'doc':
          type = 'application/msword';
          break;
        case 'docx':
          type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'xls':
          type = 'vnd.ms-excel';
          break;
        case 'xlsx':
          type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'rtf':
          type = 'application/rtf';
          break;
        case 'odt':
          type = 'application/vnd.oasis.opendocument.text';
          break;
        case 'mpeg':
          type = 'video/mpeg';
          break;
        case 'mp4':
          type = 'video/mp4';
          break;
        case 'avi':
          type = 'video/x-msvideo';
          break;
        case 'ogg':
          type = 'video/ogg';
          break;
        default:
          break;
      }
    }

    // create the blob object with content-type
    return new Blob([view], {type: type});
  }

  /**
   * Knovertovanie suboru na base64 string (vrati ako data:text/html;base64,KJHD...)
   * @param file File
   * @param trimBase boolean
   */
  static fileToBase64(file: File | Blob, trimBase = false): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        if (file.size === undefined || file.size === 0) {
          resolve('');
        } else if (event.target && typeof event.target['result'] === "string") {
          let base64 = event.target['result'] as string;
          if (trimBase && base64?.length) {
            base64 = base64.substring(base64.indexOf(',') + 1, base64.length);
          }
          resolve(base64 || '');
        } else {
          resolve('');
        }
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  }

  static clone(data: any): any {
    if (!data) {
      return  data;
    }
    return JSON.parse(JSON.stringify(data));
  }

  static handleImage(imageData: string): string {
    return imageData.includes('base64') ? imageData : ('data:image/jpeg;base64,' + imageData);
  }

  static getUrlParams(url: string): string {
    return url.split('?')[0].replace(/.*\/content\/*/gi, '');
  }

  static getLastParam(path: any[] | undefined): any | undefined {
    if (Utils.isNotEmpty(path)) {
      return path!.at(-1);
    }
    return undefined;
  }

  static scrollToTop(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  static scrollToElement(elemClass: string): void {
    setTimeout(() => {
      const classElement = document.getElementsByClassName(elemClass);
      if (classElement.length > 0) {
        classElement[0].scrollIntoView(true);
      }
    }, 100);
  }

  static openInNewWindow(url: string): void {
    const linkElem = document.createElement('a');
    linkElem.href = url;
    linkElem.target = '_blank';
    linkElem.click();
  }
}
