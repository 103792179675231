import {Injectable} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {UserType} from '../types/user.type';
import {KeycloakProfile} from 'keycloak-js';
import {ClientData} from '../openapi/client/models/client-data';
import {UserService} from '../openapi/client/services/user.service';
import {Subject} from 'rxjs';
import {ImpersonationType} from '../types/impersonation.type';
import {Utils} from '../utils/utils';
import {UserUtil} from '../utils/user-utils';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  impersonationDataKey = 'impersonationData';

  protected user!: UserType;
  protected clientData!: ClientData;
  protected impersonationData: ImpersonationType | undefined;

  showPreviewAsCompanyDialogSubject = new Subject<ImpersonationType | undefined>();

  constructor(protected keycloak: KeycloakService, private readonly userService: UserService) {

  }

  public loadUserProfile(): Promise<any> {
    console.log('load user profile');
    if (this.keycloak.isLoggedIn()) {
      return new Promise<any>((resolve, reject): void => {
        this.keycloak.loadUserProfile().then(profile => {
          this.setUser(profile, this.keycloak.getUserRoles());
          if (!UserUtil.hasAdminOrRedactorRole(this.keycloak.getUserRoles())) {
            this.userService.getAgentData().subscribe((clientData: ClientData) => {
              this.clientData = clientData;
            });
          }
          this.initImpersonationData();
          resolve(true);
        }).catch(error => reject('Failed to retrieve user profile'))
      });
    } else {
      this.clearSession();
      return this.keycloak.login();
    }
  }

  private setUser(profile: KeycloakProfile, roles: string[]): void {
    this.user = {
      id: profile.id ? profile.id : 0,
      username: profile.username ? profile.username : '',
      firstName: profile.firstName ? profile.firstName : '',
      lastName: profile.lastName ? profile.lastName : '',
      email: profile.email ? profile.email : '',
      glow_login: profile.attributes && profile.attributes['glow_login'] ? (profile.attributes['glow_login'] as Array<string>)[0] : '',
      company: profile.attributes && profile.attributes['company'] ? (profile.attributes['company'] as Array<string>)[0] : '',
      roles: roles ? roles : [],
      isAdmin: roles && roles.indexOf('ADMINISTRATOR') > -1
    } as UserType;
  }

  private initImpersonationData(): void {
    const sessionImpersonationData = localStorage.getItem(this.impersonationDataKey);
    if (sessionImpersonationData) {
      try {
        this.impersonationData = JSON.parse(sessionImpersonationData);
        if (this.impersonationData?.businessPublicationDate) {
          this.impersonationData.businessPublicationDate = new Date(this.impersonationData.businessPublicationDate);
        }
      } catch (e) {
        console.error('Chyba pri parsovani stringu na JSON', sessionImpersonationData, e);
      }
    }
  }

  public getClientData(): ClientData {
    return this.clientData;
  }

  public getUser(): UserType {
    return this.user;
  }

  /**
   * get screen name of the logged-in user (from session)
   */
  public getUserScreenName(): string | undefined {
    if (this.user) {
      return this.user.firstName + ' ' + this.user.lastName;
    }
    return undefined;
  }

  /**
   * get company of the logged-in user (from session)
   */
  public getUserCompany(): string | undefined {
    if (this.clientData) {
      return this.clientData.companyName;
    }
    if (this.user) {
      return this.user.company;
    }
    return undefined;
  }

  public getUserEmail(): string | undefined {
    if (this.clientData) {
      return this.clientData.email;
    }
    if (this.user) {
      return this.user.email;
    }
    return undefined;
  }

  setPreviewAsCompany(impersonationData: ImpersonationType | undefined): void {
    this.impersonationData = impersonationData;
    if (this.impersonationData) {
      localStorage.setItem(this.impersonationDataKey, JSON.stringify(this.impersonationData));
    } else {
      localStorage.removeItem(this.impersonationDataKey);
    }
    window.location.reload();
  }

  getPreviewAsCompanyHash(): string {
    return this.impersonationData?.hash || '';
  }

  isImpersonation(): boolean {
    return Utils.isNotEmpty(this.impersonationData?.hash);
  }

  previewAsCompany(): string | undefined {
    return this.impersonationData?.previewAsCompany?.name;
  }

  openPreviewAsCompanyDialog(): void {
    this.showPreviewAsCompanyDialogSubject.next(this.impersonationData);
  }

  clearSession(): void {
    localStorage.removeItem(this.impersonationDataKey);
  }
}
