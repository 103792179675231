import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MobileMenuService {

    showMobileMenuSubject = new Subject<boolean>();
    renderMap: Map<string, boolean> = new Map();
}
